<template>
    <div v-if="isAllowedTo('SupplierCentral/listPricingHistory')" class="sidebar-detail">
        <div class="d-flex align-items-center p-3 border-bottom">
            <mercur-button class="btn btn-icon mr-1" :to="parentRoute"><i class="fas fa-times"></i></mercur-button>
            <span class="flex-grow-1">Logs <span v-if="templateName">for {{ templateName }}</span></span>
        </div>
        <div class="sidebar-detail__content full-height-layout">
            <div
                class="sidebar-detail__spinner"
                v-if="loading"
            >
                <mercur-spinner v-if="loading"></mercur-spinner>
            </div>
            <div v-else-if="logs.length" class="fhf">
                <template-log :log="log" :accounts="accounts" v-for="(log, index) in logs" :index="index + 1" :key="log.pricingHistoryId"></template-log>
            </div>
            <div v-else>
                <p>There seem to be no logs. Please <a @click.prevent="listPricingLogs()">try again</a></p>
            </div>
        </div>
    </div>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>
<script>
import PricingHelperMethods from '../../components/pricing/PricingHelperMethods'
import CONFIG from '@root/config'
import TemplateLog from '../../components/pricing/TemplateLog'

export default {
    name: 'TemplateLogs',
    mixins: [PricingHelperMethods],
    components: { TemplateLog },
    props: {
        templateName: {
            default: null,
        },
        parentRoute: {
            required: true,
        },
    },
    data () {
        return {
            loading: false,
            logs: [],
            accounts: [],
        }
    },
    watch: {
        '$route.params.pricingRuleTemplateId' (value) {
            if (!value) {
                return
            }
            this.listPricingLogs()
        },
    },
    methods: {
        listPricingLogs () {
            this.loading = true
            const url = CONFIG.API.ROUTES.PRICING.HISTORY.LIST
            const payload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            if (this.$route.params.showSingleTemplateLogs) {
                payload.request.filterModel.pricingEntityId = {
                    filter: this.$route.params.salesPriceTaskId,
                    filterType: 'text',
                    type: 'equals',
                }
                payload.request.sortModel.push({
                    colId: 'dateCreated',
                    sort: 'desc',
                })
            } else {
                payload.request.filterModel.pricingEntityType = {
                    filter: 'PRICING_RUN',
                    filterType: 'text',
                    type: 'equals',
                }
                payload.request.filterModel.pricingEntityId = {
                    filter: this.$route.params.pricingRuleTemplateId,
                    filterType: 'text',
                    type: 'equals',
                }
                payload.request.sortModel.push({
                    colId: 'dateCreated',
                    sort: 'desc',
                })
            }
            this.addJob(url)
            this.$api.post(url, payload).then(({ data }) => {
                this.$set(this, 'logs', data.data.items)
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching template tasks',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.finishJob(url)
                this.loading = false
            })
        },
        fetchAccounts () {
            const url = CONFIG.API.ROUTES.ACCESS_CONTROL.OVERVIEW_FOR_ORGANISATION.replace('{organisationId}', this.$store.state.auth.user.organisationId)
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.accounts = data.data.items
            })
        },
    },
    created () {
        this.listPricingLogs()
        this.fetchAccounts()
    },
}
</script>
