<template>
    <div data-test="templateLogWrapper" class="log">
        <div class="log__title">
            <span class="log__index">#{{ index }}</span>
            <span class="log__meta">
                <small>
                    <strong v-if="log.pricingHistoryTitle">{{log.pricingHistoryTitle}} </strong>
                    <mercur-tooltip class="log__date">
                        <template slot="label">{{log.dateCreated}}</template>
                        {{timeAgo}}
                    </mercur-tooltip><br />
                    <em>{{getAccountNameById(log.createdBy)}}</em>
                </small>
            </span>
            <mercur-button data-test="templateLogButtonOpenClose" @click="isOpen = !isOpen" class="btn btn-icon btn-icon-sm">
                <i class="fas" :class="isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </mercur-button>
        </div>
        <div data-test="templateLogButtonOpenContent" class="log__content" v-if="isOpen">
            <table class="log__info" data-test="templateLogButtonOpenContentTableProps"  >
                <template v-for="(value, key, i) in log.pricingEntityState">
                    <tr :key="key+i"><td><b>{{key}}</b></td></tr>
                    <tr data-test="templateLogButtonOpenContentTablePropsItem" :key="i">
                        <td v-if="typeof value === 'object' && value !== null" class="pl-2 pb-2">
                            <span  v-for="(item, itemKey) in value" :key="itemKey">
                                <b>{{itemKey}}:</b> {{item}} <br>
                            </span>
                        </td>
                        <td v-else class="pl-2 pb-2" v-text="getStringValue(value)"></td>
                    </tr>
                </template>

            </table>
        </div>
    </div>
</template>

<script>
import PricingHelperMethods from './PricingHelperMethods'
export default {
    name: 'TemplateLog',
    mixins: [PricingHelperMethods],
    props: {
        log: {
            required: true,
        },
        index: {},
        accounts: {},
    },
    data () {
        return {
            isOpen: false,
            statuses: null,
            timeUpdater: null,
            timeAgo: null,
        }
    },
    methods: {
        getStringValue (value) {
            if (value === null) {
                return 'null'
            }
            return value.toString()
        },
        getAccountNameById (accountId) {
            if (!Array.isArray(this.accounts)) {
                return ''
            }
            const account = this.accounts.find(account => account.accountId === accountId)
            if (account) {
                return `${account.firstName} ${account.lastName}`
            }
            return ''
        },
        calculateTimeAgo () {
            this.timeAgo = this.$options.filters.asRelativeHumanTime(this.log.dateCreated)
        },
    },

    created () {
        this.calculateTimeAgo()
        this.timeUpdater = setInterval(this.calculateTimeAgo, 10000)
    },
    beforeDestroy () {
        clearTimeout(this.timeUpdater)
    },
}
</script>

<style lang="scss" scoped>
    .log {
        margin: 5px 10px 0;
        border-bottom: 1px solid #f0f0f0;

        &__title {
            display: flex;
            padding-bottom: 5px;
        }

        &__index {
            display: flex;
            align-items: center;
            font-size: 14px;
            flex: 1;
        }
        &__meta {
            flex: 3;
            line-height: 1.1em;
        }
        &__date {
            color: #838383;
            float: right;
        }

        &__content {
            padding: 10px 0;
            border-radius: 4px;
            font-size: 11px;
            line-height: 1.1em;
        }

        &__variation {
            border: 1px solid rgba(0,0,0,0.12);
            margin-bottom: 5px;
            padding: 5px;
        }

        &__info {
            list-style: none;
            margin-top: 0;
            border: 1px solid rgba(0,0,0,0.3);
            background-color: rgba(0,0,0,0.02);
            padding: 5px;

            td {
                word-break: break-all;
            }
        }
    }
</style>
